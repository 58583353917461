hr {
  background-color: #dcdcdc;
  border: 0
}

.clear {
  clear: both
}

.clearfix:after,.clearfix:before {
  display: table;
  content: ""
}

.clearfix:after {
  clear: both
}

a.link {
  font-size: 13px;
  font-weight: 400;
  text-decoration: underline;
  color: #d21e1e;
  width: auto;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: 1.5
}

a.link:after,a.link:before {
  content: "";
  display: table;
  clear: both
}

.title-basic {
  font-weight: 700;
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase;
  color: #d21e1e
}